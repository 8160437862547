<template>
    <div>
        <v-container fluid class="pt-0 mt-0">
            <HeaderAzul titulo="Meus" subtitulo="Livros"/>
        </v-container>
        <v-container fluid>
            <vue-pdf-embed :source="`https://app.excellentglobal.com.br/livros/${id_livro}.pdf`" />
        </v-container>
    </div>
</template>

<script>
import {mapState} from "vuex";
import HeaderAzul from "@/Views/Estrutura/HeaderAzul";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    name: "LivroFisicoCompleto",
    components: {HeaderAzul, VuePdfEmbed},
    props: ['id_livro'],
    data: () => {
        return {
            livros : []
        }
    },
    computed : {
        ...mapState(['usuarioSessao']),

    },
    methods : {
        init() {
            let c = []
            if (this.usuarioSessao.livro1) {
                c.push({
                    id_livro : 1,
                    title : '1. New Energy'
                })
            }
            if (this.usuarioSessao.livro2) {
                c.push({
                    id_livro : 2,
                    title : '2. New Decision'
                })
            }
            if (this.usuarioSessao.livro3) {
                c.push({
                    id_livro : 3,
                    title : '3. New Vision'
                })
            }
            if (this.usuarioSessao.livro4) {
                c.push({
                    id_livro : 4,
                    title : '4. New Lifestyle'
                })
            }
            this.livros = c
        }
    },
    created() {
        this.init()
    }
}
</script>

<style scoped>
    h2 {font-size: 30px; font-weight: lighter; line-height: 110%; color: #00436F;}
    h2 strong {font-weight: bold;}
</style>